var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-switch",
    _vm._b(
      {
        class: { switchStyle: _vm.textInner },
        attrs: { width: _vm.width },
        on: { change: _vm.changeSwitch },
        model: {
          value: _vm.modelValue,
          callback: function($$v) {
            _vm.modelValue = $$v
          },
          expression: "modelValue"
        }
      },
      "el-switch",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }