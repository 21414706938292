var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "goodsForm",
          attrs: {
            model: _vm.formData,
            "label-position": "right",
            "label-width": "130px",
            rules: _vm.goodsRule
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "商品标题:", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "60%" },
                attrs: {
                  clearable: "",
                  maxlength: "30",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "内部名称:", prop: "internal_name" } },
            [
              _c("el-input", {
                staticStyle: { width: "60%" },
                attrs: {
                  clearable: "",
                  maxlength: "30",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.formData.internal_name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "internal_name", $$v)
                  },
                  expression: "formData.internal_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: "商品类型：", prop: "coupon_type", required: "" }
            },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.couponTypeChange },
                  model: {
                    value: _vm.formData.coupon_type,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "coupon_type", $$v)
                    },
                    expression: "formData.coupon_type"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("直充")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("卡券")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "coupon_type", required: "" } },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "equity-type_radio",
                  on: { change: _vm.goodsTypeChange },
                  model: {
                    value: _vm.formData.type,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "type", $$v)
                    },
                    expression: "formData.type"
                  }
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: {
                        disabled: _vm.formData.coupon_type === 2,
                        label: 1
                      }
                    },
                    [_vm._v("话费慢充")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: {
                        disabled: _vm.formData.coupon_type === 2,
                        label: 2
                      }
                    },
                    [_vm._v("话费快充")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: {
                        disabled: _vm.formData.coupon_type === 2,
                        label: 3
                      }
                    },
                    [_vm._v("话费秒冲")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: {
                        disabled: _vm.formData.coupon_type === 2,
                        label: 4
                      }
                    },
                    [_vm._v("油卡")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: {
                        disabled: _vm.formData.coupon_type === 2,
                        label: 5
                      }
                    },
                    [_vm._v("电费")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: {
                        disabled: _vm.formData.coupon_type === 2,
                        label: 7
                      }
                    },
                    [_vm._v("中石化")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: {
                        disabled: _vm.formData.coupon_type === 1,
                        label: 8
                      }
                    },
                    [_vm._v("中石油")]
                  ),
                  _c("el-radio", { attrs: { label: 6 } }, [_vm._v("其他")])
                ],
                1
              ),
              _vm.formData.coupon_type === 1 &&
              [1, 2, 3].includes(_vm.formData.type)
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { prop: "out_validity", label: "对外宣称时间(h):" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "40%" },
                        attrs: { placeholder: "以小时为单位" },
                        model: {
                          value: _vm.formData.out_validity,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "out_validity", _vm._n($$v))
                          },
                          expression: "formData.out_validity"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.formData.coupon_type === 1 || _vm.formData.type === 8
            ? _c(
                "el-form-item",
                { attrs: { label: "账号类型:", required: "" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.couponType,
                            callback: function($$v) {
                              _vm.couponType = $$v
                            },
                            expression: "couponType"
                          }
                        },
                        [_vm._v("账号")]
                      )
                    ],
                    1
                  ),
                  _vm.formData.type === 7 || _vm.formData.type === 8
                    ? _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.formData.sub_coupon_type,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "sub_coupon_type", $$v)
                            },
                            expression: "formData.sub_coupon_type"
                          }
                        },
                        [_vm._v("手机号充值")]
                      )
                    : _c(
                        "el-checkbox-group",
                        {
                          attrs: { max: 2 },
                          model: {
                            value: _vm.formData.sub_coupon_type,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "sub_coupon_type", $$v)
                            },
                            expression: "formData.sub_coupon_type"
                          }
                        },
                        _vm._l(_vm.accountList, function(item) {
                          return _c(
                            "el-checkbox",
                            { key: item.id, attrs: { label: item.id } },
                            [_vm._v(_vm._s(item.value))]
                          )
                        }),
                        1
                      )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "图片:", prop: "logo" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: _vm.imageAction,
                    "show-file-list": false,
                    "before-upload": _vm.imageBeforeUpload,
                    "on-success": _vm.handleAvatarSuccess
                  }
                },
                [
                  _vm.imageUrl
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.imageUrl }
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon"
                      })
                ]
              ),
              _c("span", { staticClass: "help" }, [
                _vm._v(
                  "尺寸800*800, 大小不超过1M（图片仅支持 .jpg, .png, .jpeg格式）"
                )
              ])
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌:", prop: "brand_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: "请选择品牌",
                            remote: "",
                            "reserve-keyword": "",
                            "remote-method": _vm.getBrands
                          },
                          model: {
                            value: _vm.formData.brand_id,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "brand_id", $$v)
                            },
                            expression: "formData.brand_id"
                          }
                        },
                        _vm._l(_vm.brands, function(item) {
                          return _c("el-option", {
                            key: item.ID,
                            attrs: { label: item.name, value: item.ID }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类目:", prop: "category_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: "请选择类目",
                            remote: "",
                            "reserve-keyword": "",
                            "remote-method": _vm.getCategories
                          },
                          model: {
                            value: _vm.formData.category_id,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "category_id", $$v)
                            },
                            expression: "formData.category_id"
                          }
                        },
                        _vm._l(_vm.categories, function(item) {
                          return _c("el-option", {
                            key: item.ID,
                            attrs: { label: item.name, value: item.ID }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "销量:", prop: "base_sell_out" } },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { float: "left" },
                  attrs: { label: "虚拟销量", "label-width": "90px" }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "75%" },
                    attrs: { type: "number", autocomplete: "off" },
                    model: {
                      value: _vm.formData.base_sell_out,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "base_sell_out", _vm._n($$v))
                      },
                      expression: "formData.base_sell_out"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { float: "left" },
                  attrs: { "label-width": "90px", label: "真实销量" }
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.formData.sell_out,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "sell_out", $$v)
                      },
                      expression: "formData.sell_out"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "库存状态:", prop: "validity" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.stock_status,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "stock_status", $$v)
                    },
                    expression: "formData.stock_status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("稳定库存")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("波动库存")]),
                  _c("el-radio", { attrs: { label: 3 } }, [_vm._v("限量库存")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "售后状态:", prop: "validity" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.after_market_type,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "after_market_type", $$v)
                    },
                    expression: "formData.after_market_type"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("0售后")]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v("小于1%售后")
                  ]),
                  _c("el-radio", { attrs: { label: 3 } }, [
                    _vm._v("大于1%售后")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "使用场景:", prop: "validity" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.usage_scene,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "usage_scene", $$v)
                    },
                    expression: "formData.usage_scene"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("线上使用")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("线下门店")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "有效期:", prop: "validity" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.timeRadio,
                    callback: function($$v) {
                      _vm.timeRadio = $$v
                    },
                    expression: "timeRadio"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("小时")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("天")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("月")])
                ],
                1
              ),
              _c("el-input", {
                staticStyle: { width: "30%", display: "block" },
                attrs: { clearable: "" },
                model: {
                  value: _vm.formData.validity,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "validity", _vm._n($$v))
                  },
                  expression: "formData.validity"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品描述:" } },
            [
              _c("editor", {
                ref: "tinymce",
                staticStyle: { width: "70%" },
                attrs: {
                  init: _vm.editorInit,
                  height: _vm.editorInit.height,
                  showUpload: false
                },
                model: {
                  value: _vm.formData.description,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "description", $$v)
                  },
                  expression: "formData.description"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品原价(元):", prop: "sale_price" } },
            [
              _c("el-input", {
                staticStyle: { width: "30%" },
                attrs: {
                  disabled: _vm.type == "update",
                  placeholder: "必填以元为单位"
                },
                model: {
                  value: _vm.formData.sale_price,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "sale_price", $$v)
                  },
                  expression: "formData.sale_price"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "基础佣金(元):", prop: "base_commission" } },
            [
              _c("el-input", {
                staticStyle: { width: "30%" },
                attrs: {
                  disabled: _vm.type == "update",
                  maxlength: "10",
                  placeholder: "必填以元为单位"
                },
                model: {
                  value: _vm.formData.base_commission,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "base_commission", $$v)
                  },
                  expression: "formData.base_commission"
                }
              }),
              _c(
                "span",
                {
                  staticStyle: {
                    "margin-left": "20px",
                    color: "#FF0000",
                    "font-size": "12px"
                  }
                },
                [
                  _vm._v(
                    "客户使用默认优惠券时按照优惠券中佣金结算，未选择优惠券时按照基础佣金结算！"
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: "ios支持购买:", prop: "ios_lock", required: "" }
            },
            [
              _c("TextSwitch", {
                attrs: {
                  value: _vm.formData.ios_lock,
                  "active-text": "开",
                  "inactive-text": "关",
                  "active-color": "#1890FF",
                  "inactive-color": "#BFBFBF",
                  textInner: ""
                },
                on: {
                  "update:value": function($event) {
                    return _vm.$set(_vm.formData, "ios_lock", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态:", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "请选择状态" },
                  model: {
                    value: _vm.formData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status"
                  }
                },
                _vm._l(_vm.selectStatus, function(item) {
                  return _c("el-option", {
                    key: item.ID,
                    attrs: { label: item.name, value: item.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "关联规格：" } },
            [
              _vm._l(_vm.dynamicTags, function(tag) {
                return _c(
                  "el-tag",
                  {
                    key: tag,
                    attrs: { closable: "", "disable-transitions": false },
                    on: {
                      close: function($event) {
                        return _vm.handleClose(tag)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(tag) + " ")]
                )
              }),
              _c(
                "el-select",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: {
                    filterable: "",
                    placeholder: "请输入规格",
                    clearable: "",
                    remote: "",
                    "remote-method": _vm.getSpecsList
                  },
                  on: { clear: _vm.getSpecsList },
                  model: {
                    value: _vm.inputValue,
                    callback: function($$v) {
                      _vm.inputValue = $$v
                    },
                    expression: "inputValue"
                  }
                },
                _vm._l(_vm.sepcList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.id + "-" + item.name,
                      value: item.id + "-" + item.name
                    }
                  })
                }),
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "button-new-tag",
                  attrs: { type: "primary", plain: "", size: "small" },
                  on: { click: _vm.handleInputConfirm }
                },
                [_vm._v("添加")]
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { margin: "2% 0 2% 5%" },
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "2%" },
              attrs: { type: "primary" },
              on: { click: _vm.enterDialog }
            },
            [_vm._v("保 存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }