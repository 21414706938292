import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
export default {
  props: {
    value: {
      default: ''
    },
    textInner: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 50
    }
  },
  data: function data() {
    return {
      modelValue: this.value
    };
  },
  watch: {
    value: function value(newValue) {
      if (newValue != this.modelValue) this.modelValue = newValue;
    }
  },
  methods: {
    changeSwitch: function changeSwitch(value) {
      this.$emit('update:value', value);
      this.$emit('change', value);
    }
  }
};